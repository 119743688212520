import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Tabs,
  Tab,
  Snackbar,
  Modal,
  Button,
} from "@mui/material";
import { getSource } from "../../services/DataService";
import DeleteModal from "../../components/Modals/DeleteModal/DeleteModal";
import ListTable from "../../components/ListTable/ListTabel";
import AddNewButton from "../../components/AddNewButton";
import TabPanel from "../../components/Tab/TabPanel";
import Status from "../../components/Status/Status";
import SaveSourceModal from "./SaveApiKeysModal";
import ImportFileModal from "./ImportFileModal";
import SourcesLogs from "./SourcesLogs";
import ApiKeysImportStatistic from "./ApiKeysImportStatistic";
import { Alert } from "../../utils/alert";

import { useSourcesAPI } from "../../hooks/useSourcesAPI";
import { useLogout } from "../../hooks/useLogout";

import style from "./styles.module.css";
import "../style.css";

const ApiKeys = () => {
  const { logout } = useLogout();

  const handleOpenAlert = (type, message) => {
    setAlert({ isShow: true, type: type, message });
  };

  const {
    sourcesData: { data, isLoading, isError },
    deleteSource,
    // editSource,
  } = useSourcesAPI(handleOpenAlert);

  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "Source created successfully",
  });
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showImportFileModal, setShowImportFileModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [defaultValues, setDefaultValues] = useState(null);
  const [value, setValue] = useState(0);

  const headers = [
    {
      lable: "Source Name",
      align: "left",
    },
    {
      lable: "API Key",
      align: "left",
    },
    {
      lable: "Status",
      align: "left",
    },
    {
      lable: "Expiration date",
      align: "left",
    },
    {
      lable: " ",
      align: "center",
    },
  ];

  const columns = [
    {
      accessor: (element) => element["name"],
      Cell: (event, source) => {},
      align: "left",
    },
    {
      accessor: (element) => element["apiKey"],
      Cell: (event, source) => {
        copyToClipboard(event, source.apiKey);
      },
      align: "left",
    },
    {
      accessor: (element) => (
        <Status status={element.active} className={style.statusWrapper} />
      ),
      // Cell: (event, source) => {
      //   event.stopPropagation();
      //   handleChangeActive(source._id, source.active);
      // },
      align: "left",
    },
    {
      accessor: (element) => element.expirationAt,
      Cell: (event, source) => {},
      align: "left",
    },
  ];

  if (isLoading) {
    return (
      <Box className="wrapper-loading">
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return <h1>Something wrong</h1>;
  }

  const handleOpenSaveModal = () => setShowSaveModal(true);
  const handleCloseSaveModal = () => setShowSaveModal(false);

  const handleOpenImportFileModal = () => setShowImportFileModal(true);
  const handleCloseImportFileModal = () => setShowImportFileModal(false);

  // const handleChangeActive = (id, active) => {
  //   editSource.mutate({ data: { active: !active }, id: id });
  // };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const handleDeleteSources = () => {
    handleCloseDeleteModal();
    deleteSource.mutate(deleteId.id);
  };

  const handleOpenDeleteModal = (source) => {
    setDeleteId({ id: source._id, name: source.name });
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const redirectAddNew = () => {
    setDefaultValues(null);
    handleOpenSaveModal();
  };

  const redirectIdsource = async (source) => {
    try {
      const data = await getSource(source._id);
      setDefaultValues(data);
      handleOpenSaveModal();
    } catch (e) {
      console.warn(e);
      if (e?.response?.status === 401) {
        logout();
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const copyToClipboard = (event, item) => {
    event.stopPropagation();
    navigator.clipboard.writeText(item).then(() => {
      handleOpenAlert("success", "Key copied to clipboard");
    });
  };

  return (
    <>
      <Box className={style.container_sources_page}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
          >
            <Tab label="Sources" />
            <Tab label="Logs" />
            <Tab label="Import Statistics" />
          </Tabs>
        </Box>
        <Box className={style.sourcesButtons}>
          <Button
            onClick={handleOpenImportFileModal}
            variant="import"
            size="large"
          >
            Import File
          </Button>
          <AddNewButton redirectAddNew={redirectAddNew} text={"Source"} />
        </Box>
      </Box>
      <TabPanel value={value} index={0}>
        <Box className="wrapper-source-list">
          <ListTable
            headers={headers}
            data={data.data}
            handleDelete={handleOpenDeleteModal}
            redirectId={redirectIdsource}
            columns={columns}
          />
        </Box>
        <Snackbar
          open={alert.isShow}
          autoHideDuration={3500}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={alert.type}
            sx={{ width: "100%" }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SourcesLogs sources={data.data} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ApiKeysImportStatistic sources={data.data} />
      </TabPanel>
      <Modal
        open={showSaveModal}
        onClose={handleCloseSaveModal}
        sx={{ backdropFilter: "blur(14px)" }}
      >
        <SaveSourceModal
          handleCloseSaveModal={handleCloseSaveModal}
          defaultValues={defaultValues}
        />
      </Modal>
      <Modal
        open={showImportFileModal}
        onClose={handleCloseImportFileModal}
        sx={{ backdropFilter: "blur(14px)" }}
      >
        <ImportFileModal handleCloseSaveModal={handleCloseImportFileModal} />
      </Modal>
      <Modal
        open={showDeleteModal}
        onClose={handleCloseDeleteModal}
        sx={{ backdropFilter: "blur(14px)" }}
      >
        <DeleteModal
          handleCloseDeleteModal={handleCloseDeleteModal}
          handleDelete={handleDeleteSources}
          text={"Source"}
          name={deleteId?.name}
        />
      </Modal>
    </>
  );
};
export default ApiKeys;